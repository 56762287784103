<template>
  <div class="page">
    <div class="content">
      <img :src="imageSrc" alt="动态图片" />
    </div>
  </div>
</template>

<script>
export default {
  name: "richText",
  data() {
    return {
      imageSrc: "",
      pageTitle: "默认标题", // 默认标题
    };
  },
  mounted() {
    this.imageSrc = this.getImageFromUrl(); // 获取并设置图片链接
    this.pageTitle = this.getTitleFromUrl(); // 获取并设置页面标题
    document.title = this.pageTitle; // 设置页面的title
  },
  methods: {
    // 从URL中获取图片链接
    getImageFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      return (
        urlParams.get("image") || "https://item.qn.ddingddang.com/钻级体系.png"
      ); // 默认图片链接
    },
    // 从URL中获取页面标题
    getTitleFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("title") || "默认标题"; // 默认标题
    },
  },
};
</script>

<style>
:root {
  --van-nav-bar-icon-color: black;
}
</style>

<style scoped lang="scss">
.page {
  .content {
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
